<template>
  <div>
          <!-- ======= Article Section Main & Sidebar ======= -->
      <section class="blog">
        <div class="container ">
          <div class="row" >
            <div class="col-lg-12">
    <!-- Main Tab section start here  -->
    <div class="user-posting">
        <b-row>
          <b-col sm="12"><h5>Modify your Troupe Portfolio</h5></b-col>
        </b-row>    
        <b-row><b-col sm="12 mt-2"><p>Publish your Troupe portfolio and promote your stage programs.</p></b-col>
        </b-row>
    </div>    
    <b-col cols="12 my-4">      
              <b-form  @submit="onSubmit" @reset="onReset" v-if="show">
                <b-form-group id="input-group-2" label-for="Troupe_name" class="col-8">
                  <b-form-input
                    id="Troupe_name"
                    v-model="InpForm.Troupe_name"
                    placeholder="Troupe Name"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-2" label-for="Prog_Name" class="col-8">
                  <b-form-input
                    id="Prog_Name"
                    v-model="InpForm.Prog_Name"
                    placeholder="Program Name"
                    required
                  ></b-form-input>                
                </b-form-group>

    <b-form-group id="input-MainCatg" label-for="MainCatg" class="col-8">
        <b-form-select  
          v-model="FormMainCatg" 
          :options="RespArtsMainCatg">
        </b-form-select>
    </b-form-group>

                <b-form-group class="col-8">
                <b-form-textarea
                  id="Address"
                  size="md"
                  v-model="InpForm.troupe_address"
                  placeholder="Troupe Address"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>   
                </b-form-group>

    <b-form-group id="input-Country" label-for="Country" class="col-8">
        <b-form-select @change="EmitCountry($event)" 
          v-model="FormCountry" 
          :options="RespReadAllCountry">
        </b-form-select>
    </b-form-group>

    <b-form-group id="input-State" label-for="State" class="col-8">
        <b-form-select @change="EmitState($event)" 
          v-model="FormState" 
          :options="RespReadAllState">
        </b-form-select>
    </b-form-group>

    <b-form-group id="input-City" label-for="City" class="col-8">
        <b-form-select @change="EmitCity($event)" 
          v-model="FormCity" 
          :options="RespReadAllCity">
        </b-form-select>
    </b-form-group>  

              <b-form inline class="col-12 mt-2">
                <b-form-group id="input-group-2" label-for="troupe_cntct1">
                  <b-form-input
                    id="troupe_cntct1"
                    v-model="InpForm.troupe_cntct1"
                    placeholder="Primary Contact Number"
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="troupe_cntct2">
                  <b-form-input
                    id="troupe_cntct2"
                    v-model="InpForm.troupe_cntct2"
                    placeholder="Secondary Contact Number"
                  ></b-form-input>                
                </b-form-group>
              </b-form>              

                <b-form inline class="col-12 mt-2">
                <b-form-group id="input-group-2" label-for="troupe_email">
                  <b-form-input
                    id="troupe_email"
                    v-model="InpForm.troupe_email"
                    placeholder="Email"
                    required
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="troupe_website" >
                  <b-form-input
                    id="troupe_website"
                    v-model="InpForm.troupe_website"
                    placeholder="Website"
                    required
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="troupe_facebook">
                  <b-form-input
                    id="troupe_facebook"
                    v-model="InpForm.troupe_facebook"
                    placeholder=" Facebook"
                    required
                  ></b-form-input>                
                </b-form-group>
              </b-form>              

              <b-form inline class="col-12 mt-2">
                <b-form-group id="input-group-2" label-for="troupe_instagram">
                  <b-form-input
                    id="troupe_instagram"
                    v-model="InpForm.troupe_instagram"
                    placeholder=" Instagram"
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-2" label-for="troupe_youtube">
                  <b-form-input
                    id="troupe_youtube"
                    v-model="InpForm.troupe_youtube"
                    placeholder="Youtube"
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="troupe_twitter">
                  <b-form-input
                    id="troupe_twitter"
                    v-model="InpForm.troupe_twitter"
                    placeholder="Twitter"
                  ></b-form-input>                
                </b-form-group>                
              </b-form>

                <b-form-group class="col-8 mt-2">
                  <b-form-textarea
                    id="troupe_description"
                    size="md"
                    v-model="InpForm.troupe_description"
                    placeholder="Troupe Description"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>     


  <b-row>
    <b-col cols="1 ml-3 mb-4"><b-img class="float-left" rounded height="80" width="80" :src="PhotoPreview"></b-img></b-col>
    <b-col cols="4"><b-form-file 
              v-model="file1"
              :state="Boolean(file1)"
              placeholder="Change picture..."
              drop-placeholder="or Drop file here..."
              @change="previewImage" accept="image/*" 
            ></b-form-file> </b-col>
  </b-row>                                 

                <!-- <b-row class="my-3">                  
                  <b-col cols="12">         
                    <b-button class="float-left ml-3" variant="success"  @click="click1"> {{ButtonText}}
                    </b-button>
                    <input type="file" ref="input1"
                      style="display: none"
                      @change="previewImage" accept="image/*" >                                        
                  </b-col>                  
                </b-row>

                <b-row>
                  <b-col cols="12 my-3 ml-3" >                    
                    <b-img v-if="ShowPhoto" class="float-left" rounded height="80" width="80" :src="PhotoPreview" >                 
                    </b-img>
                      <b-spinner v-else-if="ShowSpinner" small
                        v-for="variant in variants"
                        :variant="variant"
                        :key="variant"
                      ></b-spinner>                
                  </b-col>  
                </b-row>                -->

                <!-- <b-form-group class="col-8 mt-2">
                <b-form-textarea
                  id="troupe_programs"
                  size="md"
                  v-model="InpForm.troupe_programs"
                  placeholder="Program Description"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
                </b-form-group>                

                <b-form-group class="col-8 mt-2">
                <b-form-textarea
                  id="troupe_artists"
                  size="md"
                  v-model="InpForm.troupe_artists"
                  placeholder="Artist details"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
                </b-form-group>                

                <b-form-group class="col-8 mt-2">
                <b-form-textarea
                  id="troupe_activities"
                  size="md"
                  v-model="InpForm.troupe_activities"
                  placeholder="Troupe Activities"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
                </b-form-group>                

                <b-form-group class="col-8 mt-2">
                <b-form-textarea
                  id="troupe_achievement"
                  size="md"
                  v-model="InpForm.troupe_achievement"
                  placeholder="Troupe Achievements"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
                </b-form-group>  -->
                              
                <!-- <b-form-checkbox class="col-8 mt-2"
                  id="checkbox-1"
                  v-model="InpForm.premium_ind"
                  name="checkbox-1"
                  value="Y"
                  unchecked-value="N"
                >
                  Premium Profile?
                </b-form-checkbox>      -->

                <!-- Media Section starts here >> users_troupe_media -->
                 <!-- <b-form-group  id="input-group-2" label-for="" class="col-8 mt-2">
                  <b-form-input
                    id="Video1"
                    v-model="InpForm.Video1"
                    placeholder="Video1"
                  ></b-form-input>
                  <b-form-input 
                    id="Video2"
                    v-model="InpForm.Video2"
                    placeholder="Video2"
                  ></b-form-input>
                  <b-form-input
                    id="Brochure"
                    v-model="InpForm.Brochure"
                    placeholder="Brochure"
                  ></b-form-input>
                  <b-form-input
                    id="Biodata"
                    v-model="InpForm.Biodata"
                    placeholder="Biodata"
                  ></b-form-input>
                </b-form-group>               -->

                <b-button pill type="submit" variant="primary">Update</b-button>
                <b-button pill type="reset" variant="danger">Cancel</b-button>
              </b-form>        
    </b-col>
            </div>       

          </div><!-- End row -->
        </div><!-- End container -->
      </section><!-- End Blog Section -->  

  </div>
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'
import {fb} from '../firebase'

Vue.use(Vueaxios,axios)
  export default {
    data() {
      return {
      RespArray: [],        
      // Input form variables
      InpForm: {
      Troupe_name: '',
      Prog_Name: '',
      TroupeType: 'default',
      troupe_address: '',
      troupe_cntct1: '',
      troupe_cntct2: '',
      troupe_email: '',
      troupe_website: '',
      troupe_facebook: '',
      troupe_instagram: '',
      troupe_youtube: '',
      troupe_twitter: '',
      troupe_description: '',
      troupe_programs: '',
      troupe_artists: '',  
      troupe_activities: '',  
      troupe_achievement: '',  
      premium_ind: 'N',  
      Video1: '',   
      Video2: '',   
      Brochure: '',   
      Biodata: '',   
      },
      // Input form variables
      FormMainCatg: 'default', 
      FormMultpleCatg: [],  
      ComponentKey: 0,
      FormCountry: 'default',
      FormState: 'default',
      FormCity: 'default',
      // Axios Resp array variables
      RespReadAllCountry: [{ value: 'default', text: 'Select Country' }],
      RespReadAllState: [{ value: 'default', text: 'Select State' }],      
      RespReadAllCity: [{ value: 'default', text: 'Select City' }],
      IndPlaceChgd: false,                  
      RespArtsMainCatg: 
      [
        { value: 'default', text: 'Select Main Category' },
      ],                  
      // Firebase image upload variables
      PhotoPreview: null,
      PhotoUrl: 'https://storage.googleapis.com/thiramedia/troupes/',
      imageData: null, 
      file1: null,
      // Response array variables
      RespIns: [],
      RespGetMaxID: [ {tbl_next_seq: '0'} ],         
      // Other variables
      show: true,
      NextSeqNo: '',
      ShowSpinner: false,
      ShowPhoto: false,
      variants: ['primary', 'danger', 'warning'],
      ButtonText: 'Upload cover photo',
      }
    },
    mounted()
    {
      this.ReadAllCountry()
      this.ReadArtsMainCatg()
      this.Read4EditTProfile(this.$route.params.SeqNo)
    },    
    methods: { 
      onSubmit(event) {
        event.preventDefault()
        this.ModifyTProfile(this.$route.params.SeqNo)
        if (this.imageData != null)
        {this.onUpload(this.$route.params.SeqNo)}
      },
    
      EmitCountry() {
          console.log(this.FormCountry)
          this.ReadAllStates(this.FormCountry)
          this.IndPlaceChgd=true
        },

      EmitState() {
          console.log(this.FormState)
          this.ReadAllCities(this.FormState)
        },

      EmitCity() {
          console.log(this.FormCity)
        },
        
      ReadArtsMainCatg(){  
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadArtsMainCatg')
        .then((resp)=>{
        // this.RespArtsCatg.push(resp.data.ReadArtsMainCatg);
          resp.data.ReadArtsMainCatg.forEach(element => this.RespArtsMainCatg.push(element))
      })
      },

     ReadAllCountry(){  
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadAllCountry')
        .then((resp)=>{
        // this.RespArtsCatg.push(resp.data.ReadAllCountry);
          resp.data.ReadAllCountry.forEach(element => this.RespReadAllCountry.push(element))
      })
      },

      ReadAllStates(GetCountry){
        this.RespReadAllState.splice(0);
        this.FormState= 'default', 
        this.RespReadAllState.push({ value: 'default', text: 'Select State' })           
        const REQformData1 = new FormData();
        REQformData1.append('country_id', GetCountry);
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadAllStates', REQformData1)
        .then((resp)=>{
        // this.RespArtsCatg.push(resp.data.ReadAllStates);
          resp.data.ReadAllStates.forEach(element => this.RespReadAllState.push(element))
      })
      },

      ReadAllCities(GetCity){
        this.RespReadAllCity.splice(0);
        this.FormCity= 'default', 
        this.RespReadAllCity.push({ value: 'default', text: 'Select City' })   
        const REQformData2 = new FormData();
        REQformData2.append('state_id', GetCity);
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadAllCities', REQformData2)
        .then((resp)=>{
        // this.RespArtsCatg.push(resp.data.ReadAllCities);
          resp.data.ReadAllCities.forEach(element => this.RespReadAllCity.push(element))
      })
      },
      
      Read4EditTProfile(RecToFetch)
      {
        const REQformData = new FormData();
        REQformData.append('seq_no', RecToFetch);          
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=Read4EditTProfile',REQformData)
        .then((resp)=>{
        this.RespArray=resp.data.Read4EditTProfile;
        const FirstObject = resp.data.Read4EditTProfile[0];        
        this.InpForm.seq_no = FirstObject.seq_no;
        this.InpForm.Troupe_name = FirstObject.troupe_name;
        this.InpForm.Prog_Name = FirstObject.troupe_pgm_name;
        this.InpForm.TroupeType = FirstObject.troupe_type;
        this.FormMainCatg = FirstObject.catg_main
        this.InpForm.troupe_address = FirstObject.troupe_address;
        this.RespReadAllState.push(FirstObject.prof_state)
        this.FormState = FirstObject.prof_state
        this.RespReadAllCity.push(FirstObject.prof_city)
        this.FormCity = FirstObject.prof_city
        this.FormCountry = FirstObject.prof_country
        // console.log('this.FormCountry',this.FormCountry)
        this.InpForm.troupe_cntct1 = FirstObject.troupe_cntct1;
        this.InpForm.troupe_cntct2 = FirstObject.troupe_cntct2;
        this.InpForm.troupe_email = FirstObject.troupe_email;
        this.InpForm.troupe_website = FirstObject.troupe_website;
        this.InpForm.troupe_facebook = FirstObject.troupe_facebook;
        this.InpForm.troupe_instagram = FirstObject.troupe_instagram;
        this.InpForm.troupe_youtube = FirstObject.troupe_youtube;
        this.InpForm.troupe_twitter = FirstObject.troupe_twitter;
        this.PhotoPreview=FirstObject.troupe_photo;
        this.InpForm.troupe_description = FirstObject.troupe_description;
        this.InpForm.premium_ind = FirstObject.premium_ind;
      })
      },

      ModifyTProfile(UpdSeqNo){  
        const REQformData = new FormData();
        REQformData.append('seq_no', UpdSeqNo);
        REQformData.append('troupe_name', this.InpForm.Troupe_name);
        REQformData.append('troupe_pgm_name', this.InpForm.Prog_Name);
        REQformData.append('troupe_type', 'Troupe');
        REQformData.append('catg_main', this.FormMainCatg);
        REQformData.append('troupe_address', this.InpForm.troupe_address);
        // REQformData.append('prof_country', this.FormCountry);
        // REQformData.append('prof_state', this.FormState);
        // REQformData.append('prof_city', this.FormCity);
        REQformData.append('troupe_cntct1', this.InpForm.troupe_cntct1);
        REQformData.append('troupe_cntct2', this.InpForm.troupe_cntct2);
        REQformData.append('troupe_email', this.InpForm.troupe_email);
        REQformData.append('troupe_website', this.InpForm.troupe_website);
        REQformData.append('troupe_facebook', this.InpForm.troupe_facebook);
        REQformData.append('troupe_instagram', this.InpForm.troupe_instagram);
        REQformData.append('troupe_youtube', this.InpForm.troupe_youtube);
        REQformData.append('troupe_twitter', this.InpForm.troupe_twitter);
        REQformData.append('troupe_description', this.InpForm.troupe_description);
        REQformData.append('troupe_photo', this.PhotoUrl + UpdSeqNo + '/image1.jpeg');
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ModifyTProfile',REQformData)
        .then((resp)=>{
          alert(resp.data.message)
          this.ModifyPlace(UpdSeqNo)
          this.ClearForms()
          })
        .catch(error => {
          alert(error)
          })
      },

     ModifyPlace(UpdSeqNo){
       console.log('this.IndPlaceChgd',this.IndPlaceChgd)
       if(this.IndPlaceChgd)
       {
        const REQformData = new FormData();
        REQformData.append('seq_no', UpdSeqNo);
        REQformData.append('table_name', 'troupe_profile_main');
        REQformData.append('prof_city', this.FormCity);
        REQformData.append('prof_state', this.FormState);
        REQformData.append('prof_country', this.FormCountry);            
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ModifyPlace',REQformData)
        .then(()=>{
          // alert(resp.data.message)
          // this.ClearForms()
          })
        .catch(error => {
          alert(error)
          })
       }
      },       
          
     ClearForms(){
        this.$router.replace('/user/dashboard')
        this.InpForm.Troupe_name = '';
        this.InpForm.Prog_Name = '';
        this.InpForm.TroupeType = 'default';
        this.InpForm.TroupeCatgMain = 'default';
        this.InpForm.troupe_address = '';
        this.InpForm.troupe_cntct1 = '';
        this.InpForm.troupe_cntct2 = '';
        this.InpForm.troupe_email = '';
        this.InpForm.troupe_website = '';
        this.InpForm.troupe_facebook = '';
        this.InpForm.troupe_instagram = '';
        this.InpForm.troupe_youtube = '';
        this.InpForm.troupe_twitter = '';
        this.InpForm.troupe_description = '';
        this.InpForm.troupe_programs = '';
        this.InpForm.troupe_artists = '';  
        this.InpForm.troupe_activities = '';  
        this.InpForm.troupe_achievement = '';   
        this.InpForm.premium_ind = '';    
        this.InpForm.Video1 = '';   
        this.InpForm.Video2 = '';   
        this.InpForm.Brochure = '';   
        this.InpForm.Biodata = '';
        this.imageData=null;
        this.file1=null;
        // this.ShowSpinner=false;
        // this.ShowPhoto=false;
        this.ComponentKey=1;
        // this.ButtonText='Upload cover photo';
      },        
                 
      onReset(event) {
        event.preventDefault()
        this.ClearForms()
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
        this.show = true
        })
      },

// Firebase image upload methods
      click1() {
        this.$refs.input1.click()   
      },
      
      previewImage(event) {
        // this.ShowSpinner=true;
        this.uploadValue=0;
        this.PhotoPreview=null;
        this.imageData = event.target.files[0];
        // this.ButtonText='Submit to upload...'
      },

      onUpload(){
        this.PhotoPreview=null;
        // const storageRef=fb.storage().ref(`${this.imageData.name}`).put(this.imageData);
        const storageRef=fb.storage().ref().child('troupes/' + this.NextSeqNo +'/' + 'image1.jpeg').put(this.imageData);
        storageRef.on(`state_changed`,snapshot=>{
        this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
          }, error=>{console.log(error.message)},
        ()=>{this.uploadValue=100;
            storageRef.snapshot.ref.getDownloadURL().then(()=>{
                // this.ShowSpinner=false;
                // this.PhotoPreview =url;
                // this.ShowPhoto=true;
                // console.log(this.PhotoPreview)
              });
            }      
          );
       },
    },
 
  }
</script>

