<template>
  <div>
          <!-- ======= Article Section Main & Sidebar ======= -->
      <section class="blog">
        <div class="container ">
          <div class="row" >
            <div class="col-lg-12">
    <!-- Main Tab section start here  -->
    <div class="user-posting">
        <b-row>
          <b-col sm="12"><h5>Modify your Performer Portfolio</h5></b-col>
        </b-row>    
        <b-row><b-col sm="12 mt-2"><p>Publish your Artist portfolio, showcase your talent and promote your stage programs. </p></b-col>
        </b-row>
    </div>    
    <b-col cols="12 my-4" v-for="Data in RespArray" v-bind:key="Data.id">      
              <b-form  @submit="onSubmit" @reset="onReset" v-if="show">
                <b-form-group id="input-group-2" label-for="profile_name" class="col-12">
                  <b-form-input
                    id="profile_name"
                    v-model="InpForm.profile_name"
                    placeholder="Profile Name"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-2" label-for="profile_title" class="col-12">
                  <b-form-input
                    id="profile_title"
                    v-model="InpForm.profile_title"
                    placeholder="Profile Title"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="input-ProfType" label-for="ProfType" class="col-12">
                  <b-form-select 
                    v-model="InpForm.ProfType" 
                    :options="ListProfType">
                  </b-form-select>
                </b-form-group>                  

    <b-form-group id="input-MainCatg" label-for="MainCatg" class="col-12">
        <b-form-select  
          v-model="FormMainCatg" 
          :options="RespArtsMainCatg">
        </b-form-select>
    </b-form-group>    

    <b-form-group id="input-MultpleCatg" label-for="MultpleCatg" class="col-12">
        <b-form-select
          v-model="FormMultpleCatg" 
          :options="RespMultpleCatg" 
          multiple 
          :select-size="5">
        </b-form-select>

    </b-form-group>
                <b-form-group class="col-12">
                <b-form-textarea
                  id="Address"
                  size="md"
                  v-model="InpForm.profile_address"
                  placeholder="Profile Address"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>   
                </b-form-group>               

    <b-form-group id="input-Country" label-for="Country" class="col-8">
        <b-form-select @change="EmitCountry($event)" 
          v-model="FormCountry" 
          :options="RespReadAllCountry">
        </b-form-select>
    </b-form-group>

    <b-form-group id="input-State" label-for="State" class="col-8">
        <b-form-select @change="EmitState($event)" 
          v-model="FormState" 
          :options="RespReadAllState">
        </b-form-select>
    </b-form-group>

    <b-form-group id="input-City" label-for="City" class="col-8">
        <b-form-select @change="EmitCity($event)" 
          v-model="FormCity" 
          :options="RespReadAllCity">
        </b-form-select>
    </b-form-group>  

              <b-form inline class="col-12 mt-2">
                <b-form-group id="input-group-2" label-for="profile_cntct1">
                  <b-form-input
                    id="profile_cntct1"
                    v-model="InpForm.profile_cntct1"
                    placeholder="Primary Contact Number"
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="profile_cntct2">
                  <b-form-input
                    id="profile_cntct2"
                    v-model="InpForm.profile_cntct2"
                    placeholder="Secondary Contact Number"
                  ></b-form-input>                
                </b-form-group>
              </b-form>              

                <b-form inline class="col-12 mt-2">
                <b-form-group id="input-group-2" label-for="profile_email">
                  <b-form-input
                    id="profile_email"
                    v-model="InpForm.profile_email"
                    placeholder="Email"
                    required
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="profile_website" >
                  <b-form-input
                    id="profile_website"
                    v-model="InpForm.profile_website"
                    placeholder="Website"
                    required
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="profile_facebook">
                  <b-form-input
                    id="profile_facebook"
                    v-model="InpForm.profile_facebook"
                    placeholder=" Facebook"
                    required
                  ></b-form-input>                
                </b-form-group>
              </b-form>              

              <b-form inline class="col-12 mt-2">
                <b-form-group id="input-group-2" label-for="profile_instagram">
                  <b-form-input
                    id="profile_instagram"
                    v-model="InpForm.profile_instagram"
                    placeholder=" Instagram"
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-2" label-for="profile_youtube">
                  <b-form-input
                    id="profile_youtube"
                    v-model="InpForm.profile_youtube"
                    placeholder="Youtube"
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="profile_twitter">
                  <b-form-input
                    id="profile_twitter"
                    v-model="InpForm.profile_twitter"
                    placeholder="Twitter"
                  ></b-form-input>                
                </b-form-group>                
              </b-form>

                <b-form-group class="col-12 mt-2">
                <b-form-textarea
                  id="profile_description"
                  size="md"
                  v-model="InpForm.profile_description"
                  placeholder="Description"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>              
                </b-form-group>

  <b-row>
    <b-col cols="1 ml-3 mb-4"><b-img class="float-left" rounded height="80" width="80" :src="PhotoPreview"></b-img></b-col>
    <b-col cols="4"><b-form-file 
              v-model="file1"
              :state="Boolean(file1)"
              placeholder="Change picture..."
              drop-placeholder="or Drop file here..."
              @change="previewImage" accept="image/*" 
            ></b-form-file> </b-col>
  </b-row>                

         
                <b-button pill type="submit" variant="primary">Update</b-button>
                <b-button pill type="reset" variant="danger">Cancel</b-button>
              </b-form>        
    </b-col>
            </div>       

          </div><!-- End row -->
        </div><!-- End container -->
      </section><!-- End Blog Section -->  

  </div>
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'
import {fb} from '../firebase'

Vue.use(Vueaxios,axios)
  export default {
    data() {
      return {
      RespArray: [],        
      // Input form variables
      InpForm: {
      seq_no: '',
      profile_name: '',
      profile_title: '',
      ProfType: 'default',
      profile_address: '',
      profile_cntct1: '',
      profile_cntct2: '',
      profile_email: '',
      profile_website: '',
      profile_facebook: '',
      profile_instagram: '',
      profile_youtube: '',
      profile_twitter: '',
      profile_description: '',
      profile_programs: '',
      profile_trainings: '',  
      profile_participations: '',  
      profile_awards: '',   
      premium_ind: 'N',  
      Video1: '',   
      Video2: '',   
      Brochure: '',   
      Biodata: '',   
      },
      // Input form variables
      FormMainCatg: 'default', 
      FormMultpleCatg: [],  
      ComponentKey: 0,
      FormCountry: 'default',
      FormState: 'default',
      FormCity: 'default',
      // Axios Resp array variables
      RespReadAllCountry: [{ value: 'default', text: 'Select Country' }],
      RespReadAllState: [{ value: 'default', text: 'Select State' }],      
      RespReadAllCity: [{ value: 'default', text: 'Select City' }],    
      IndPlaceChgd: false,
      // Firebase image upload variables
      PhotoPreview: null,
      PhotoUrl: 'https://storage.googleapis.com/thiramedia/artists/perfomr',
      imageData: null, 
      file1: null,
      // Response array variables
      RespIns: [],
      // Select drop down options
      ListProfType: [
        { value: 'default', text: 'Select Profile Type' },
        { value: 'Performer', text: 'Artist - Performing Arts'},
        { value: 'Painter', text: 'Artist - Paintings'},
        { value: 'Poet', text: 'Artist - Poet'},
        { value: 'Actor', text: 'Artist - Actor'},
      ],
      RespArtsMainCatg: 
      [
        { value: 'default', text: 'Select Main Category' },
      ],
      RespMultpleCatg: 
      [
        { value: 'default', text: 'SELECT 5 more related categories', disabled: true },
      ],                
      // Other variables
      show: true,
      NextSeqNo: '',
      ShowSpinner: false,
      ShowPhoto: false,
      variants: ['primary', 'danger', 'warning'],
      ButtonText: 'Upload cover photo',
      }
    },
    mounted()
    {
      this.ReadAllCountry()
      this.ReadArtsMainCatg()
      this.Read4EditAProfile(this.$route.params.SeqNo)
    },    
    methods: {
      onSubmit(event) {
        event.preventDefault()
        this.ModifyAProfile(this.$route.params.SeqNo)
        if (this.imageData != null)
        {this.onUpload(this.$route.params.SeqNo)}
      },

      EmitCountry() {
          console.log(this.FormCountry)
          this.ReadAllStates(this.FormCountry)
          this.IndPlaceChgd=true
        },

      EmitState() {
          console.log(this.FormState)
          this.ReadAllCities(this.FormState)
        },

      EmitCity() {
          console.log(this.FormCity)
        },       

      ReadArtsMainCatg(){  
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadArtsMainCatg')
        .then((resp)=>{
        // this.RespArtsCatg.push(resp.data.ReadArtsMainCatg);
          resp.data.ReadArtsMainCatg.forEach(element => this.RespArtsMainCatg.push(element))
          resp.data.ReadArtsMainCatg.forEach(element => this.RespMultpleCatg.push(element))
      })
      },       

      ReadAllCountry(){  
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadAllCountry')
        .then((resp)=>{
        // this.RespArtsCatg.push(resp.data.ReadAllCountry);
          resp.data.ReadAllCountry.forEach(element => this.RespReadAllCountry.push(element))
      })
      },

      ReadAllStates(GetCountry){
        this.RespReadAllState.splice(0);
        this.FormState= 'default', 
        this.RespReadAllState.push({ value: 'default', text: 'Select State' })           
        const REQformData1 = new FormData();
        REQformData1.append('country_id', GetCountry);
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadAllStates', REQformData1)
        .then((resp)=>{
        // this.RespArtsCatg.push(resp.data.ReadAllStates);
          resp.data.ReadAllStates.forEach(element => this.RespReadAllState.push(element))
      })
      },

      ReadAllCities(GetCity){
        this.RespReadAllCity.splice(0);
        this.FormCity= 'default', 
        this.RespReadAllCity.push({ value: 'default', text: 'Select City' })   
        const REQformData2 = new FormData();
        REQformData2.append('state_id', GetCity);
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadAllCities', REQformData2)
        .then((resp)=>{
        // this.RespArtsCatg.push(resp.data.ReadAllCities);
          resp.data.ReadAllCities.forEach(element => this.RespReadAllCity.push(element))
      })
      }, 
  
      Read4EditAProfile(RecToFetch){
        const REQformData = new FormData();
        REQformData.append('seq_no', RecToFetch);          
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=Read4EditAProfile',REQformData)
        .then((resp)=>{
        this.RespArray=resp.data.Read4EditAProfile;
        const FirstObject = resp.data.Read4EditAProfile[0];
        this.InpForm.seq_no = FirstObject.seq_no;
        this.InpForm.profile_name = FirstObject.profile_name;
        this.InpForm.profile_title = FirstObject.profile_title;
        this.InpForm.ProfType = FirstObject.profile_type;
        this.InpForm.profile_address = FirstObject.profile_address;
        this.InpForm.profile_cntct1 = FirstObject.profile_cntct1;
        this.InpForm.profile_cntct2 = FirstObject.profile_cntct2;
        this.InpForm.profile_email = FirstObject.profile_email;
        this.InpForm.profile_website = FirstObject.profile_website;
        this.InpForm.profile_facebook = FirstObject.profile_facebook;
        this.InpForm.profile_instagram = FirstObject.profile_instagram;
        this.InpForm.profile_youtube = FirstObject.profile_youtube;
        this.InpForm.profile_twitter = FirstObject.profile_twitter;
        this.InpForm.profile_description = FirstObject.profile_description;  
        this.FormMainCatg = FirstObject.catg_main
        this.FormMultpleCatg[0] = FirstObject.catg1
        this.FormMultpleCatg[1] = FirstObject.catg2
        this.FormMultpleCatg[2] = FirstObject.catg3
        this.FormMultpleCatg[3] = FirstObject.catg4
        this.FormMultpleCatg[4] = FirstObject.catg5
        this.RespReadAllState.push(FirstObject.prof_state)
        this.FormState = FirstObject.prof_state
        this.RespReadAllCity.push(FirstObject.prof_city)
        this.FormCity = FirstObject.prof_city
        this.FormCountry = FirstObject.prof_country
        // console.log('this.FormCountry',this.FormCountry)
        this.PhotoPreview=FirstObject.profile_photo;
      })
      }, 
          
      ModifyAProfile(UpdSeqNo){
        const REQformData = new FormData();
        REQformData.append('seq_no', UpdSeqNo);
        REQformData.append('profile_name', this.InpForm.profile_name);
        REQformData.append('profile_title', this.InpForm.profile_title);
        REQformData.append('profile_type', this.InpForm.ProfType);
        REQformData.append('catg_main', this.FormMainCatg);
        REQformData.append('profile_address', this.InpForm.profile_address);
        // REQformData.append('prof_country', this.FormCountry);
        // REQformData.append('prof_state', this.FormState.value);
        // REQformData.append('prof_city', this.FormCity.value);
        REQformData.append('profile_cntct1', this.InpForm.profile_cntct1);
        REQformData.append('profile_cntct2', this.InpForm.profile_cntct2);
        REQformData.append('profile_email', this.InpForm.profile_email);
        REQformData.append('profile_website', this.InpForm.profile_website);
        REQformData.append('profile_facebook', this.InpForm.profile_facebook);
        REQformData.append('profile_instagram', this.InpForm.profile_instagram);
        REQformData.append('profile_youtube', this.InpForm.profile_youtube);
        REQformData.append('profile_twitter', this.InpForm.profile_twitter);
        REQformData.append('profile_photo', this.PhotoUrl + UpdSeqNo + '/image1.jpeg');
        REQformData.append('profile_description', this.InpForm.profile_description);
        REQformData.append('profile_programs', this.InpForm.profile_programs);
        REQformData.append('profile_trainings', this.InpForm.profile_trainings);
        REQformData.append('profile_participations', this.InpForm.profile_participations);
        REQformData.append('profile_awards', this.InpForm.profile_awards);
        REQformData.append('catg1', this.FormMultpleCatg[0]);
        REQformData.append('catg2', this.FormMultpleCatg[1]);
        REQformData.append('catg3', this.FormMultpleCatg[2]);
        REQformData.append('catg4', this.FormMultpleCatg[3]);
        REQformData.append('catg5', this.FormMultpleCatg[4]);
        REQformData.append('premium_ind', this.InpForm.premium_ind);
        REQformData.append('Video1', this.InpForm.Video1);
        REQformData.append('Video2', this.InpForm.Video2);
        REQformData.append('Brochure', this.InpForm.Brochure);
        REQformData.append('Biodata', this.InpForm.Biodata);
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ModifyAProfile',REQformData)
        .then((resp)=>{
          alert(resp.data.message)
          this.ModifyPlace(UpdSeqNo)
          this.ClearForms()
          })
        .catch(error => {
          alert(error)
          })
      },

     ModifyPlace(UpdSeqNo){
       console.log('this.IndPlaceChgd',this.IndPlaceChgd)
       if(this.IndPlaceChgd)
       {
        const REQformData = new FormData();
        REQformData.append('seq_no', UpdSeqNo);
        REQformData.append('table_name', 'users_profile_main');
        REQformData.append('prof_city', this.FormCity);
        REQformData.append('prof_state', this.FormState);
        REQformData.append('prof_country', this.FormCountry);            
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ModifyPlace',REQformData)
        .then(()=>{
          // alert(resp.data.message)
          // this.ClearForms()
          })
        .catch(error => {
          alert(error)
          })
       }
      },       
       
     ClearForms(){  
        this.$router.replace('/user/dashboard')
        this.InpForm.profile_name = '';
        this.InpForm.profile_title = '';
        this.InpForm.ProfType = 'default';
        this.InpForm.profile_address = '';
        this.InpForm.profile_cntct1 = '';
        this.InpForm.profile_cntct2 = '';
        this.InpForm.profile_email = '';
        this.InpForm.profile_website = '';
        this.InpForm.profile_facebook = '';
        this.InpForm.profile_instagram = '';
        this.InpForm.profile_youtube = '';
        this.InpForm.profile_twitter = '';
        this.InpForm.profile_description = '';
        this.InpForm.profile_programs = '';
        this.InpForm.profile_trainings = '';  
        this.InpForm.profile_participations = '';  
        this.InpForm.profile_awards = '';  
        this.InpForm.premium_ind = '';    
        this.InpForm.Video1 = '';   
        this.InpForm.Video2 = '';   
        this.InpForm.Brochure = '';   
        this.InpForm.Biodata = '';
        this.imageData=null;
        this.file1=null;
      this.FormCountry='default',
      this.FormState='default',
      this.FormCity='default',  
      this.FormMainCatg= 'default', 
      this.FormMultpleCatg= [], 
      this.PhotoPreview=null;            
        // this.ShowSpinner=false;
        // this.ShowPhoto=false;
        // this.ButtonText='Upload cover photo';
        this.ComponentKey=1;
      },        
                 
      onReset(event) {
        event.preventDefault()
        this.ClearForms()
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
        this.show = true
        })
      },

// Firebase image upload methods
      click1() {
        this.$refs.input1.click()   
      },
      
      previewImage(event) {
        // this.ShowSpinner=true;
        this.uploadValue=0;
        this.PhotoPreview=null;
        this.imageData = event.target.files[0];
        // this.ButtonText='Submit to upload...'
      },

      onUpload(UpdSeqNo){
        this.PhotoPreview=null;
        // const storageRef=fb.storage().ref(`${this.imageData.name}`).put(this.imageData);
        const storageRef=fb.storage().ref().child('artists/' + 'perfomr'+ UpdSeqNo +'/' + 'image1.jpeg').put(this.imageData);
        storageRef.on(`state_changed`,snapshot=>{
        this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
          }, error=>{console.log(error.message)},
        ()=>{this.uploadValue=100;
            storageRef.snapshot.ref.getDownloadURL().then(()=>{
                // this.ShowSpinner=false;
                // this.PhotoPreview =url;
                // this.ShowPhoto=true;
                // console.log(this.PhotoPreview)
              });
            }      
          );
       },
    },

    computed: {
    }    
  }
</script>

