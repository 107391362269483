<template>
    <div>
      <Home_navbar HeaderClass="fixed-top"></Home_navbar>

      <!-- ======= Article Section Main & Sidebar ======= -->
      <section class="blog">
        <div class="container">
          <div class="row">
            <!-- The main article section starte here -->
            <div class="col-lg-8 my-5 offset-2">
              <article class="entry entry-single">
                <!-- <p>If you are an existing user, enter your email ID to receive a login link.</p> -->
                <!-- Portfolio Search Box & Add Button-->
                <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                      <b-form-group
                        id="input-group-1"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          v-model="NewUserEmailID"
                          placeholder="Enter your email ID"
                          required
                        ></b-form-input>
                      </b-form-group>

                      <b-button class="mr-3" pill type="submit" variant="primary">Find my email ID!</b-button>
                      <!-- <a href=# @click="ShowRegisterModal" class="font-weight-light text-danger">New-user </a> -->
                      <!-- <b-button v-b-modal.modal-prevent-closing pill type="submit" variant="success"><router-link :to="{name:'dashboard'}">New User?</router-link></b-button> -->

                </b-form>

                <!-- <div v-if="RespEmailID!=null">
                    <p>Your email id {{RespEmailID}} is already known to us and linked with the name {{RespName}}. </p>
                    <p>Request a Password now!</p>
                </div> -->
    <b-modal v-model="modalShow" @ok="SendLoginLink" ><b-alert variant="success" show>Hello {{NewUserEmailID}}, Your email id is already linked with a profile. Click OK to receive a login link.</b-alert></b-modal>                
    <b-modal v-model="ModalNotFnd" @ok="ShowRegisterModal"><b-alert variant="success" show>The email {{NewUserEmailID}} Not found! Click OK to create a new user account!</b-alert></b-modal>                
              
              </article>
<!-- =============================
Register Modal 
============================= -->
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="Create Account!"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">

        <b-form-group
          label-for="email-input"
          invalid-feedback="Email ID is required"
          :state="emailState"
        >
          <b-form-input
            id="email-input"
            v-model="NewUseremail"
            placeholder="Your email ID"
            :state="emailState"
            required
          ></b-form-input>
        </b-form-group>    

        <b-form-group
          label-for="name-input"
          invalid-feedback="Name is required"
          :state="nameState"
        >
          <b-form-input
            id="name-input"
            v-model="NewUserName"
            placeholder="Your Full Name"
            :state="nameState"
            required
          ></b-form-input>
        </b-form-group>            
       
      </form>
    </b-modal>
    <b-modal v-model="ModalRegDone" @ok="NextRouteLogin"><b-alert variant="success" show>Success! Check your Email and follow the instructions.</b-alert></b-modal>

            </div>
            <!-- Article Right side bar Ends here -->
          </div><!-- End row -->
        </div><!-- End container -->
      </section><!-- End Blog Section -->

    </div><!-- End Main Div  -->
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'
import { mapGetters } from "vuex";
import {fb} from '../firebase'
// import emailjs from 'emailjs-com';


Vue.use(Vueaxios,axios)
  export default {
    data() {
      return {
        form: {
          username: '',
          password: '',
        },
        show: true,
        danger: false,
        NewUserEmailID: '',
        NewUserName: '',
        NewUserPswd: '',
        NewUseremail: '',
        nameState: null,
        pswdState: null,
        emailState: null,
        RespUserEmail: null,
        RespEmailID: null,
        RespName: null,
        RespIns: [],
        RespInsSuccess: false,
        modalShow: false,
        ModalNotFnd: false,
        ModalRegDone: false,
      }
    },
    methods: {
      onSubmit(event) {
        event.preventDefault()
        this.FindNewUserEmailID(this.NewUserEmailID)        
      },

      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.form.username = ''
        this.form.password = ''
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.danger=false
        this.$nextTick(() => {
        this.show = true
        })
      },

      // register(){
      //   fb.auth().createUserWithEmailAndPassword(this.NewUseremail, this.NewUserPswd)
      //     .then(()=>{
      //         console.log('success');
      //         })
      //     .catch((error)=>{
      //     var errorCode = error.code;
      //     var errorMessage = error.message;
      //     if (errorCode == 'auth/weak-password') {
      //       alert('The password is too weak.');
      //     } else {
      //       alert(errorMessage);
      //     }
      //     console.log(error);
      //   });
      // },

      InsUserMasterIndex() {
        // console.log('this.NewUserName',this.NewUserName)
        // console.log('this.NewUseremail',this.NewUseremail)
        // console.log('this.NewUserPswd',this.NewUserPswd)
        const INSformData = new FormData();
        INSformData.append('user_email', this.NewUseremail);
        INSformData.append('user_name', this.NewUserName);          
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=InsUserMasterIndex',INSformData)
        .then((resp)=>{
          // this.RespIns=resp.data.message;
          // this.RespInsSuccess=resp.error;
          console.log('this.NewUseremail',resp.data.email)
          this.SignInLinkToEmail(resp.data.email)
          // alert(this.RespIns)
          })
        .catch(error => {
          alert(error)
          })      
      },

      FindNewUserEmailID(InpUserEmail) {
        const REQformData = new FormData();
        REQformData.append('email_id', InpUserEmail);          
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=FindNewUserEmailID',REQformData)
        .then((resp)=>{
        this.RespUserEmail=resp.data.FindNewUserEmailID;
        const FirstObject = resp.data.FindNewUserEmailID[0];
        this.RespEmailID = FirstObject.user_email;
        this.RespName = FirstObject.user_name;
        this.modalShow=true;
      })
        .catch(error => {
          console.log(error)
          // alert('Email ID Not found! Click to create a new user account!')
          this.ModalNotFnd=true
          this.NewUseremail=this.NewUserEmailID
          // console.log('NewUseremail:', this.NewUseremail)
          })      
      },      

      SignInLinkToEmail(InpUserEmail1) {
      var actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: 'https://thiraseela.kiranvt.com/user/confirm-login',
        // url: 'https://thiraseela.kiranvt.com/user/SetUserPswd',
        // This must be true.
        handleCodeInApp: true,
      };        
        fb.auth().sendSignInLinkToEmail(InpUserEmail1, actionCodeSettings)
        .then(() => {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem('emailForSignIn', InpUserEmail1);
          this.ModalRegDone=true
        })
        .catch(() => {
          console.log('Error!!')
        });
      },

      // FbResetPassword() {       
      //   fb.auth().sendPasswordResetEmail(this.NewUserEmailID)
      //   .then(() => {
      //     console.log('Success!!')
      //   })
      //   .catch(() => {
      //     console.log('Error!!')
      //   });
      // },      
 
    //  sendEmail() {
    //     console.log('this.NewUserName',this.NewUserName)
    //     console.log('this.NewUseremail',this.NewUseremail)
    //     console.log('this.NewUserPswd',this.NewUserPswd)       
        // var username=this.NewUserName;
        // var useremail=this.NewUseremail;
        // var userpswd=this.NewUserPswd;
        // console.log('this.username',username)
        // console.log('this.useremail',useremail)
        // console.log('this.userpswd',userpswd)        
      // emailjs.send('service_ucq3yji', 'template_wqqyvic', {user_email: this.NewUseremail, user_name: this.NewUserName, user_pswd: this.NewUserPswd}, 'user_DW3ZBRMOzHyQXkqdcP6bH')
      //   .then(() => {
      //       // console.log('SUCCESS!', result.status, result.text);
      //       alert('Thanks! Your message has been sent successfully!')
      //   }, (error) => {
      //       console.log('FAILED...', error);
      //       alert('FAILED! Something wrong! Please send an email to thiraseelacom@gmail.com!')
      //   });
        // this.ClearForms()
    // },

      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.nameState = valid
        return valid
      },
      resetModal() {
        this.NewUserName = ''
        this.NewUserPswd = ''
        this.NewUseremail = ''
        this.nameState = null
        this.pswdState = null
        this.emailState = null
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },
      SendLoginLink(){
        this.SignInLinkToEmail(this.RespEmailID);
      },
      NextRouteLogin() {
        this.$router.replace('/')
      },

      ShowRegisterModal() {
        this.$bvModal.show('modal-prevent-closing')
      }, 

      handleSubmit() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          return
        }
        // this.register()
        this.InsUserMasterIndex();
        // if (this.RespInsSuccess)
        //   {this.sendEmail()}
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing')
        })
      }
    },
  computed: {
    ...mapGetters({
// map `this.user` to `this.$store.getters.user`
      user: "user"
    })
  },
  }
</script>

