<template>
    <div>
      <Home_navbar HeaderClass="fixed-top"></Home_navbar>

      <!-- ======= Article Section Main & Sidebar ======= -->
      <section class="blog">
        <div class="container">
          <div class="row">
            <!-- The main article section starte here -->
            <div class="col-lg-6 my-5 offset-2">
              <article class="entry entry-single">
                <!-- Portfolio Search Box & Add Button-->
                <!-- <b-alert show variant="danger" v-if="danger">Incorrect User Name/Password!</b-alert> -->
                <b-form @submit="onSubmit" @reset="onReset">
                      <!-- <b-form-group
                        id="input-group-1"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          v-model="NewUserPswd"
                          placeholder="Create a password for your account"
                          required
                        ></b-form-input>
                      </b-form-group> -->
                     <b-alert v-if="emailverified" variant="success" show>Success! Your email id is verified!</b-alert>
                      <b-button v-if="emailverified" pill type="submit" variant="primary">Take Me to Dashboard!</b-button>
                </b-form>

              </article>
            </div>
            <!-- Article Right side bar Ends here -->
          </div><!-- End row -->
        </div><!-- End container -->
      </section><!-- End Blog Section -->
    </div><!-- End Main Div  -->
</template>

<script>
import {fb} from '../firebase'
  export default {
    data() {
      return {
        NewUserPswd: null,
        emailverified: false,
        email: null,
      }
    },
    mounted()
    {
      this.VerifysignInWithEmailLink()
    },    
    methods: {
      onSubmit(event) {
        event.preventDefault()
        this.signInWithEmailLink()
      },

      onReset(event) {
        event.preventDefault()
        // Reset our form values
        // Trick to reset/clear native browser form validation state
        this.NewUserPswd=null
        this.danger=false
        this.$nextTick(() => {
        })
      },
     VerifysignInWithEmailLink() {
        // Confirm the link is a sign-in with email link.
        if (fb.auth().isSignInWithEmailLink(window.location.href)) {
          // Additional state parameters can also be passed via URL.
          // This can be used to continue the user's intended action before triggering
          // the sign-in operation.
          // Get the email if available. This should be available if the user completes
          // the flow on the same device where they started it.
          this.email = window.localStorage.getItem('emailForSignIn');
          if (!this.email) {
            // User opened the link on a different device. To prevent session fixation
            // attacks, ask the user to provide the associated email again. For example:
            this.email = window.prompt('Please provide your email for confirmation');
          }
          // this.emailverified=true
          this.signInWithEmailLink()
        }
      },        
      signInWithEmailLink() {
          fb.auth().signInWithEmailLink(this.email, window.location.href)
            .then((result) => {
              // Clear email from storage.
              window.localStorage.removeItem('emailForSignIn');
              console.log(result.user)
              this.$router.replace('/user/dashboard')
              // You can access the new user via result.user
              // Additional user info profile not available via:
              // result.additionalUserInfo.profile == null
              // You can check if the user is new or existing:
              // result.additionalUserInfo.isNewUser
            })
            .catch((error) => {
              alert(error.message) 
              // Some error occurred, you can inspect the code: error.code
              // Common errors could be invalid email and invalid or expired OTPs.
            });
      },             
    },
  }
</script>

