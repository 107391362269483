<template>
  <div>
      <section class="blog">
        <div class="container ">
          <div class="row" >
            <div class="col-lg-12">
    <!-- Main Tab section start here  -->
     <div class="user-posting">
        <b-row>
          <b-col sm="12"><h5>Modify Your Stage Programs</h5></b-col>
        </b-row>    
        <b-row><b-col sm="12 mt-2"><p>Publish your program details and promote it thorugh thiraseela.com</p></b-col>
        </b-row>
    </div>    
    <b-col cols="12 my-4">
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                <b-form-group id="input-PrgName" label-for="PrgName" class="col-8">
                  <b-form-input
                    id="PrgName"
                    v-model="InpForm.PrgName"
                    placeholder="Program Name"
                    :maxlength="MaxChar"
                    required
                  ></b-form-input>
                </b-form-group>

    <b-form class="col-8">
    <b-form-group id="input-ShowType" label-for="ShowType" >
        <b-form-select @change="GetAllProfiles();EmitShowType($event)" 
          v-model="FormShowType" 
          :options="ListShowType">
        </b-form-select>
    </b-form-group>
    </b-form>

    <b-form class="col-8">
      <b-form-group id="input-group-2" label-for="ShowBy">
        <b-form-input list="my-list-id"
          @change="EmitShowBy($event)"
          id="ShowBy"
          v-model="FormShowBy"
          placeholder="Search & select your Profile by name"
        ></b-form-input>                
      </b-form-group>
    </b-form>

    <datalist id="my-list-id">
      <option>Not found? Enter Artist/Troupe/Band name</option>
      <option v-for="Data in RespAllProfiles" v-bind:key="Data.profile_id">{{ Data.profile_name }}</option>
    </datalist> 
                <b-form-group class="col-8">
                  <b-form-textarea
                    id="textarea"
                    size="md"
                    v-model="InpForm.PrgDesc"
                    placeholder="Program Description"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>  
                </b-form-group>                            
              

    <b-form-group id="input-MainCatg" label-for="MainCatg" class="col-8">
        <b-form-select  
          v-model="FormMainCatg" 
          :options="RespArtsMainCatg">
        </b-form-select>
    </b-form-group>    


                <b-form-group class="col-8">
                  <b-form-textarea
                    id="textarea"
                    size="md"
                    v-model="InpForm.Address"
                    placeholder="Contact Address"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>  
                </b-form-group>

    <b-form-group id="input-Country" label-for="Country" class="col-8">
        <b-form-select @change="EmitCountry($event)" 
          v-model="FormCountry" 
          :options="RespReadAllCountry">
        </b-form-select>
    </b-form-group>

    <b-form-group id="input-State" label-for="State" class="col-8">
        <b-form-select @change="EmitState($event)" 
          v-model="FormState" 
          :options="RespReadAllState">
        </b-form-select>
    </b-form-group>

    <b-form-group id="input-City" label-for="City" class="col-8">
        <b-form-select @change="EmitCity($event)" 
          v-model="FormCity" 
          :options="RespReadAllCity">
        </b-form-select>
    </b-form-group>  

              <b-form inline class="col-12 mt-2">
                <b-form-group id="input-group-2" label-for="cntct1">
                  <b-form-input
                    id="cntct1"
                    v-model="InpForm.cntct1"
                    placeholder="Primary Contact Number"
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="cntct2">
                  <b-form-input
                    id="cntct2"
                    v-model="InpForm.cntct2"
                    placeholder="Secondary Contact Number"
                  ></b-form-input>                
                </b-form-group>
              </b-form>

              <b-form inline class="col-12 mt-2">
                <b-form-group id="input-group-2" label-for="email" >
                  <b-form-input
                    id="email"
                    v-model="InpForm.email"
                    placeholder="Email"
                  ></b-form-input>                
                </b-form-group>   

                <b-form-group id="input-group-2" label-for="website">
                  <b-form-input
                    id="website"
                    v-model="InpForm.website"
                    placeholder="Website"
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="facebook">
                  <b-form-input
                    id="facebook"
                    v-model="InpForm.facebook"
                    placeholder=" Facebook"
                  ></b-form-input>                
                </b-form-group>                 
              </b-form>

            <b-form inline class="col-12 mt-2">
                <b-form-group id="input-group-2" label-for="instagram">
                  <b-form-input
                    id="instagram"
                    v-model="InpForm.instagram"
                    placeholder=" Instagram"
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-2" label-for="youtube">
                  <b-form-input
                    id="youtube"
                    v-model="InpForm.youtube"
                    placeholder="Youtube"
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="twitter">
                  <b-form-input
                    id="twitter"
                    v-model="InpForm.twitter"
                    placeholder="Twitter"
                  ></b-form-input>                
                </b-form-group>                
              </b-form>                                         
    

  <b-row>
    <b-col cols="1 ml-3 mb-4"><b-img class="float-left" rounded height="80" width="80" :src="PhotoPreview"></b-img></b-col>
    <b-col cols="4"><b-form-file 
              v-model="file1"
              :state="Boolean(file1)"
              placeholder="Change picture..."
              drop-placeholder="or Drop file here..."
              @change="previewImage" accept="image/*" 
            ></b-form-file> </b-col>
  </b-row>   
           
                <div cols="12" class="my-3 ml-3">
                  <b-button pill type="submit" variant="primary">Submit</b-button>
                  <b-button pill type="reset" variant="danger">Cancel</b-button>
                </div>
      </b-form>        
    </b-col>

            </div>       

          </div><!-- End row -->
        </div><!-- End container -->
      </section><!-- End Blog Section -->  



  </div>
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'
import {fb} from '../firebase'

Vue.use(Vueaxios,axios)
  export default {
    data() {
      return {
      // Input form variables
      InpForm: {
        PrgName: '',
        ArtName: '',
        ArtDesc: '',
        PrgDesc: '',
        PrgCatg: 'default',
        // PrgType: 'default',
        Address: '',
        cntct1: '',
        cntct2: '',
      email: '',
      website: '',
      facebook: '',
      instagram: '',
      youtube: '',
      twitter: '',
      },
      ComponentKey: 0,
      FormMainCatg: 'default', 
      FormMultpleCatg: [],  
      FormCountry: 'default',
      FormState: 'default',
      FormCity: 'default',
      MaxChar: 100,
      RespReadAllCountry: [{ value: 'default', text: 'Select Country' }],
      RespReadAllState: [{ value: 'default', text: 'Select State' }],      
      RespReadAllCity: [{ value: 'default', text: 'Select City' }], 
      IndPlaceChgd: false,
      // Firebase image upload variables
      PhotoPreview: null,
      PhotoUrl: 'https://storage.googleapis.com/thiramedia/stageshows/',
      imageData: null,
      file1: null,
      // Response array variables
      RespIns: [],
      RespGetMaxID: [ {tbl_next_seq: '0'} ],
      RespArtsMainCatg: 
      [
        { value: 'default', text: 'Select Main Category' },
      ],
      RespMultpleCatg: 
      [
        { value: 'default', text: 'SELECT 5 more related categories', disabled: true },
      ],
      FormShowType: 'default',
      FormShowBy: '',
      RespAllProfiles: [],
      ListShowType: [
        { value: 'default', text: 'Select program owner' },
        { value: 'T', text: 'Troupe/Band'},
        { value: 'A', text: 'School/Academy'},
        { value: 'P', text: 'Artist/Performer'},
      ],              
      // Select drop down options
      // ListPrgType: [
      //   { value: 'default', text: 'Select Program Type' },
      //   { value: 'Troupe', text: 'Troupe/Group' },
      //   { value: 'Artist', text: 'Artist/Individual' },
      // ],          
      // Other variables
      show: true,
      // ShowSpinner: false,
      ShowPhoto: false,
      variants: ['primary', 'danger', 'warning'],
      // ButtonText: 'Upload cover photo',
      }
    },
    mounted()
    {
      this.ReadAllCountry()
      this.ReadArtsMainCatg()
      this.GetAllProfiles()
      this.Read4EditStageShows(this.$route.params.SeqNo)      
    },    
    methods: {
      onSubmit(event) { 
        event.preventDefault()
        this.ModifyStageShows(this.$route.params.SeqNo)
        if (this.imageData != null)
        {this.onUpload(this.$route.params.SeqNo)}        
      },

      SetShowBy(event) {
          this.ChildShowBy = event
          console.log('ChildShowBy',this.ChildShowBy)
      },
      
      SetShowType(event) {
          this.ChildShowType = event
          console.log('ChildShowType',this.ChildShowType)
      },        
      

      EmitCountry() {
          console.log(this.FormCountry)
          this.ReadAllStates(this.FormCountry)
          this.IndPlaceChgd=true

        },

      EmitState() {
          console.log(this.FormState)
          this.ReadAllCities(this.FormState)
        },

      EmitCity() {
          console.log(this.FormCity)
        },       

     ReadArtsMainCatg(){  
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadArtsMainCatg')
        .then((resp)=>{
        // this.RespArtsCatg.push(resp.data.ReadArtsMainCatg);
          resp.data.ReadArtsMainCatg.forEach(element => this.RespArtsMainCatg.push(element))
          resp.data.ReadArtsMainCatg.forEach(element => this.RespMultpleCatg.push(element))
      })
      },
      
      GetAllProfiles(){
        const REQformData = new FormData();
        REQformData.append('profile_type', this.FormShowType);
        // console.log(this.FormShowType)
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=GetAllProfiles',REQformData)
        .then((resp)=>{
        this.RespAllProfiles=resp.data.GetAllProfiles;
      })
      },        

      ReadAllCountry(){  
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadAllCountry')
        .then((resp)=>{
        // this.RespArtsCatg.push(resp.data.ReadAllCountry);
          resp.data.ReadAllCountry.forEach(element => this.RespReadAllCountry.push(element))
      })
      },

      ReadAllStates(GetCountry){
        this.RespReadAllState.splice(0);
        this.FormState= 'default', 
        this.RespReadAllState.push({ value: 'default', text: 'Select State' })           
        const REQformData1 = new FormData();
        REQformData1.append('country_id', GetCountry);
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadAllStates', REQformData1)
        .then((resp)=>{
        // this.RespArtsCatg.push(resp.data.ReadAllStates);
          resp.data.ReadAllStates.forEach(element => this.RespReadAllState.push(element))
      })
      },

      ReadAllCities(GetCity){
        this.RespReadAllCity.splice(0);
        this.FormCity= 'default', 
        this.RespReadAllCity.push({ value: 'default', text: 'Select City' })   
        const REQformData2 = new FormData();
        REQformData2.append('state_id', GetCity);
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadAllCities', REQformData2)
        .then((resp)=>{
        // this.RespArtsCatg.push(resp.data.ReadAllCities);
          resp.data.ReadAllCities.forEach(element => this.RespReadAllCity.push(element))
      })
      },
      
      Read4EditStageShows(RecToFetch){
        const REQformData = new FormData();
        REQformData.append('seq_no', RecToFetch);          
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=Read4EditStageShows',REQformData)
        .then((resp)=>{
        this.RespArray=resp.data.Read4EditStageShows;
        const FirstObject = resp.data.Read4EditStageShows[0];
        this.InpForm.seq_no = FirstObject.seq_no;
        this.InpForm.PrgName = FirstObject.show_name;
        this.FormShowBy = FirstObject.show_owner;
        this.InpForm.ArtDesc = FirstObject.show_owner_desc;
        this.FormMainCatg = FirstObject.catg_main
        this.InpForm.PrgDesc = FirstObject.show_desc;
        this.ListShowType.push(FirstObject.show_type);
        this.FormShowType = FirstObject.show_type;
        this.InpForm.Address = FirstObject.address;

        this.RespReadAllState.push(FirstObject.prof_state)
        this.FormState = FirstObject.prof_state
        this.RespReadAllCity.push(FirstObject.prof_city)
        this.FormCity = FirstObject.prof_city
        this.FormCountry = FirstObject.prof_country

        this.InpForm.cntct1 = FirstObject.contact1;
        this.InpForm.cntct2 = FirstObject.contact2;
        this.InpForm.email = FirstObject.email; 
        this.InpForm.website = FirstObject.website;
        this.InpForm.facebook = FirstObject.facebook;
        this.InpForm.instagram = FirstObject.instagram;  
        this.InpForm.youtube = FirstObject.youtube;
        this.InpForm.twitter = FirstObject.twitter;               
        this.PhotoPreview=FirstObject.logo;
      })
      },       
          
      ModifyStageShows(UpdSeqNo){
        const REQformData = new FormData();
        REQformData.append('seq_no', UpdSeqNo);
        REQformData.append('show_name', this.InpForm.PrgName);
        REQformData.append('show_owner', this.FormShowBy);
        REQformData.append('show_owner_desc', this.InpForm.ArtDesc);
        REQformData.append('catg_main', this.FormMainCatg);
        REQformData.append('show_desc', this.InpForm.PrgDesc);
        REQformData.append('show_type', this.show_type);
        REQformData.append('address', this.InpForm.Address);
        // REQformData.append('city', this.FormCity);
        // REQformData.append('state', this.FormState);
        // REQformData.append('country', this.FormCountry);

        REQformData.append('contact1', this.InpForm.cntct1);
        REQformData.append('contact2', this.InpForm.cntct2);
        REQformData.append('email', this.InpForm.email);
        REQformData.append('website', this.InpForm.website);
        REQformData.append('facebook', this.InpForm.facebook); 
        REQformData.append('instagram', this.InpForm.instagram); 
        REQformData.append('youtube', this.InpForm.youtube);
        REQformData.append('twitter', this.InpForm.twitter);             
        REQformData.append('logo', this.PhotoUrl + UpdSeqNo + '/image1.jpg');                
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ModifyStageShows',REQformData)
        .then((resp)=>{
          alert(resp.data.message)
          this.ModifyPlace(UpdSeqNo)
          this.ClearForms()
          })
        .catch(error => {
          alert(error)
          })
      },  
      
     ModifyPlace(UpdSeqNo){
       console.log('this.IndPlaceChgd',this.IndPlaceChgd)
       if(this.IndPlaceChgd)
       {
        const REQformData = new FormData();
        REQformData.append('seq_no', UpdSeqNo);
        REQformData.append('table_name', 'stage_shows_main');
        REQformData.append('prof_city', this.FormCity);
        REQformData.append('prof_state', this.FormState);
        REQformData.append('prof_country', this.FormCountry);            
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ModifyPlace',REQformData)
        .then(()=>{
          // alert(resp.data.message)
          // this.ClearForms()
          })
        .catch(error => {
          alert(error)
          })
       }
      },      
       
      ClearForms(){ 
        this.$router.replace('/user/dashboard')
        this.InpForm.PrgName='',
        this.InpForm.ArtName='',
        this.InpForm.ArtDesc='',
        this.InpForm.PrgDesc='',
        this.InpForm.PrgCatg='default',
        // this.InpForm.PrgType='default',
        this.InpForm.Address='',
        this.InpForm.cntct1='',
        this.InpForm.cntct2='',
        this.InpForm.email='',
        this.InpForm.website='',
        this.InpForm.facebook='',
        this.InpForm.instagram='',
        this.InpForm.youtube='',
        this.InpForm.twitter='',
        this.imageData=null;
        this.file1=null;
        // this.ShowSpinner=false;
        // this.ShowPhoto=false;
        this.ComponentKey=1;
      this.FormCountry='default',
      this.FormState='default',
      this.FormCity='default',  
      this.FormMainCatg= 'default', 
      this.FormMultpleCatg= [], 
      this.PhotoPreview=null;           
        // this.ButtonText='Upload cover photo';
      },
                 
      onReset(event) {
        event.preventDefault()
        this.ClearForms()
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
        this.show = true
        })
      },

// Firebase image upload methods
      click1() {
        this.$refs.input1.click()   
      },
      
      previewImage(event) {
        // this.ShowSpinner=true;
        this.uploadValue=0;
        this.PhotoPreview=null;
        this.imageData = event.target.files[0];
        // this.ShowSpinner=false;
        // this.ButtonText='Submit to upload...'
      },

      onUpload(UpdSeqNo){
        this.PhotoPreview=null;
        // const storageRef=fb.storage().ref(`${this.imageData.name}`).put(this.imageData);
        const storageRef=fb.storage().ref().child('stageshows/' + UpdSeqNo +'/' + 'image1.jpg').put(this.imageData);
        storageRef.on(`state_changed`,snapshot=>{
        this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
          }, error=>{console.log(error.message)},
        ()=>{this.uploadValue=100;
            storageRef.snapshot.ref.getDownloadURL().then(()=>{
                // this.ShowSpinner=false;
                // this.PhotoPreview =url;
                // this.ShowPhoto=true;
                // console.log(this.PhotoPreview)
              });
            }      
          );
       },
    },  
  }
</script>

