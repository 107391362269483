<template>
    <div>
       <Home_navbar HeaderClass="fixed-top"></Home_navbar>
              <div>
                <b-tabs content-class="mt-3">
                  <!-- First Tab to Display Records -->
                  <b-tab title="List Of Profiles" active>
                    <div>
                      <b-table striped hover outlined responsive="sm"
                        :items="RespAllRecs"
                        :fields="ListFields"
                        :per-page="perPage"
                        :current-page="currentPage"    
                      >
                      <template v-slot:cell(actions)="data">
                        <b-button variant="danger" title="Delete Profile" @click="DelTableRec(data.item.seq_no,data.item.prof_type)">D</b-button>
                        <b-button  variant="success" title="Edit Profile" @click="RouteEdit(data.item.seq_no,data.item.prof_type)">E</b-button>
                        
                        <!-- <b-button v-else-if="data.item.prof_type='Troupe'" variant="success" title="Edit Profile">
                          <router-link :to="{name:'update-TProfile',params:{ SeqNo:data.item.seq_no}}"><span>E</span></router-link>
                        </b-button>
                        <b-button v-else-if="data.item.prof_type='A'" variant="success" title="Edit Profile">
                          <router-link :to="{name:'update-PProfile',params:{ SeqNo:data.item.seq_no}}"><span>E</span></router-link>
                        </b-button>
                        <b-button v-else variant="success" title="Edit Profile">
                          <router-link to="#"><span>E</span></router-link>
                        </b-button>   
                                                              -->
                      </template>
                      </b-table>

                    </div>        
                  </b-tab>
                  <!-- Second Tab to Display Records -->
                  <b-tab title="Read me">
                    <div class="thira-title">
                      <p>Welcome to thiraseela.com, You can use our paltform to Showcase your artist, Academy and 
                        Troupe portfolios and also promote your stage shows and other performing related activities 
                        like online classes, workshops and performances.  </p>
                      <p>We would recommend to create Artist, Troupe and Academy portfolios first before promoting 
                        your stage shows and other art related activities.</p>
                        <h4>Artist Portfolio</h4>
                        <p>Showcase your artist portfolio. You can Publish about you, your Trainings, Participation, Achievements, awards and complete contact details.</p>
                        <h4>Troupe Portfolio</h4>
                        <p>Showcase your band portfolio. You can Publish about your troupe, your programs, Participation, Achievements, awards and complete contact details.</p>
                        <h4>Academy Portfolio</h4>
                        <p>Showcase your Academy portfolio. You can Publish about your academy/Art centers, Curriculum details, Participation, Achievements, awards and complete contact address.</p>
                        <hr/><h4>Promote Stage Shows</h4>
                        <p>Okay! Now its the time to promote your stage shows. Once you create your Artist/Troue/Academy portfolio, submit the stage programs offered by you and promote it to wider range of audiance and organizers to get more online bookings and enquiries.</p>
                        <h4>Promote Online Classes</h4>
                        <p>If you are offering online Classes for any art forms, submit the details and get new admissions from all over the world.</p>
                        <hr/><h4>Make us your online partner</h4>
                        <p>You can also share your upcoming events, Your event photos, and your Achievements with us. We will share this with millions of art lovers across the world</p>
                    </div>        
                  </b-tab>                  
                </b-tabs>
              </div>     


    
    </div><!-- End Main Div  -->
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'
import { mapGetters } from "vuex";

Vue.use(Vueaxios,axios)
  export default {
    data() {
      return {
      // Pagination variables
      perPage: 20,
      currentPage: 1,
      RespAllRecs: [],  
      RespDelRecs: [],      
      // Table display columns
      // ListFields: [{key: 'seq_no',sortable: true}, "prof_type", "prof_name","date_created","actions"],
      ListFields: ["seq_no", "prof_type", "prof_name","date_created","actions"],

      }
    },
    mounted()
    {
      // console.log('UserEmailID',  )
      this.UserProfilesByEmail()
      this.UserPromoByEmail()
    },     
    methods: {
      UserProfilesByEmail(){
        const SearchformData = new FormData();
        SearchformData.append('UserEmailId', this.user.data.email);
        axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=UserProfilesByEmail', SearchformData)
        .then((resp)=>{
        //this.RespAllRecs=resp.data.UserProfilesByEmail;
        //this.RespAllRecs.push(resp.data.UserProfilesByEmail);
        resp.data.UserProfilesByEmail.forEach(element => this.RespAllRecs.push(element))
       }).catch(error => console.log(error));
       },
      UserPromoByEmail(){
        const SearchformData1 = new FormData();
        SearchformData1.append('UserEmailId', this.user.data.email);
        axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=UserPromoByEmail', SearchformData1)
        .then((resp)=>{
        //this.RespAllRecs=resp.data.UserPromoByEmail;
        //this.RespAllRecs.push(resp.data.UserPromoByEmail);
        resp.data.UserPromoByEmail.forEach(element => this.RespAllRecs.push(element))
       }).catch(error => console.log(error));
       },       

      DelTableRec(SeqNo,ProfType){
        const InsformData = new FormData();
        InsformData.append('seq_no', SeqNo);
        if ( ProfType == 'Performer') 
        { InsformData.append('table_name', 'users_profile_main') }
        if ( ProfType == 'Troupe') 
        { InsformData.append('table_name', 'troupe_profile_main') }
        if ( ProfType == 'Academy') 
        { InsformData.append('table_name', 'academy_profile_main') }
        if ( ProfType == 'Stage Show') 
        { InsformData.append('table_name', 'stage_shows_main') }
        if ( ProfType == 'Online Classes') 
        { InsformData.append('table_name', 'promotion_main') }                
        if (confirm('You are permenently deleting this profile, Please confirm ?'))
        axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=DelTableRec', InsformData)
        .then((resp)=>{
          this.RespDelRecs=resp.data.message;
          alert(this.RespDelRecs)
          this.RespAllRecs.splice(0)
          this.UserProfilesByEmail()
          this.UserPromoByEmail()
          })
        .catch(error => {
          alert(error)
          })
       },
     RouteEdit(SeqNo, ProfType){
        const InsformData = new FormData();
        InsformData.append('seq_no', SeqNo);
        if ( ProfType == 'Performer') 
        { this.$router.push({ name: 'update-PProfile', params: { SeqNo: SeqNo } });  }
        if ( ProfType == 'Troupe') 
        { this.$router.push({ name: 'update-TProfile', params: { SeqNo: SeqNo } }) ; }
        if ( ProfType == 'Academy') 
        { this.$router.push({ name: 'update-AProfile', params: { SeqNo: SeqNo } }) ; }
        if ( ProfType == 'Stage Show') 
        { this.$router.push({ name: 'update-StageShow', params: { SeqNo: SeqNo } }) ; } 
        if ( ProfType == 'Online Classes') 
        { this.$router.push({ name: 'update-promotions', params: { SeqNo: SeqNo } }) ; }                
       },       
    },

  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user"
    })
  }   
  }
</script>

<style>

.admin-main span{
  color:#ffffff;
}

</style>
